.icon_box {
  background-color: #f8f9fa;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
}
.icon_box i {
  float: left;
  color: #d9232d;
  font-size: 40px;
}
.icon_box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.icon_box h4 a {
  color: #556270;
  transition: 0.3s;
}
.icon_box p {
  line-height: 24px;
  font-size: 14px;
}
.icon_box:hover h4 a {
  color: #d9232d;
}
.cursor {
  cursor: pointer;
}
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
