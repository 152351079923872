/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f8f9fa;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #e6636a;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #556270;
}

.carousel,
.carousel-inner,
.carousel-item,
.carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.carousel-item::before {
  content: "";
  background-color: rgba(30, 35, 40, 0.6);
}

.carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}
.carousel-inner .carousel-item,
.carousel-inner .active.carousel-item-start,
.carousel-inner .active.carousel-item-end {
  opacity: 0;
}
.carousel-inner .active,
.carousel-inner .carousel-item-next.carousel-item-start,
.carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev,
.carousel-inner .active.carousel-item-start,
.carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-control-next-icon:hover,
.carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.carousel-control-prev-icon::before {
  content: "\f284";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel-control-next-icon::before {
  content: "\f285";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #d9232d !important;
  border-color: #d9232d !important;
  background: #fff !important;
}

@media (max-width: 992px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    background: #d9232d !important;
  }
}

.tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.skills .progress {
  height: 10px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress-bar-wrap {
  background: #edeff1;
}
.skills .progress-bar {
  height: 10px;
  transition: 0.9s;
  background-color: #6b7b8d;
}

/* advanced - enter transition 800ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(-10px);
}
.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
}
.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}
