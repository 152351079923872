/*--------------------------------------------------------------
# InfrastructureFoundation
--------------------------------------------------------------*/
.InfrastructureFoundation h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.InfrastructureFoundation h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}
.InfrastructureFoundation ul {
  list-style: none;
  padding: 0;
}
.InfrastructureFoundation ul li {
  padding: 10px 0 0 28px;
  position: relative;
}
.InfrastructureFoundation ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #d9232d;
}
.InfrastructureFoundation p:last-child {
  margin-bottom: 0;
}
