/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 18px 0;
    background: #f8f9fa;
    min-height: 40px;
    margin-top: 80px;
  }
  .breadcrumbs h2 {
    font-size: 32px;
    font-weight: 300;
    margin: 0;
  }
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6b7b8d;
    content: "/";
  }
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 58px;
    }
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs h2 {
      margin-bottom: 10px;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }