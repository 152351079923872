/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq_item {
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 1px solid white;
  }
  .faq_item i {
    color: #dee2e6;
    font-size: 20px;
    float: left;
    line-height: 0;
    padding: 2px 0 0 0;
    margin: 0;
  }
  .faq_item h4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin: 0 0 10px 28px;
    font-family: "Poppins", sans-serif;
  }
  .faq_item p {
    font-size: 15px;
  }