.page_header {
    background: white;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
  }
  .page_header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
  }
  .page_header.header-inner-pages {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
  .header_link {
    color: #556270;
  }
  
  .navbar {
    padding: 0;
  }
  .navbar_ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar_li {
    position: relative;
  }
  .navbar_link, .navbar_link:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #556270;
    white-space: nowrap;
    transition: 0.3s;
  }
  .navbar_link i, .navbar_link:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar_link:hover, .active, .active:focus {
    color: #d9232d;
  }
  .getstarted, .getstarted:focus {
    background: #d9232d;
    padding: 8px 25px !important;
    margin-left: 30px;
    border-radius: 4px;
    font-weight: 400;
    color: #fff !important;
  }
  .getstarted:hover, .getstarted:focus:hover {
    color: #fff;
    background: #e1444d;
  }
  .dropdown_ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  .dropdown_li {
    min-width: 200px;
  }
  .dropdown_link {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #d9232d;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile_nav_toggle {
    color: #556270;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile_nav_toggle.bi_x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile_nav_toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  .navbar_mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(63, 73, 83, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar_mobile .mobile_nav_toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar_mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar_mobile a, .navbar_mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #556270;
  }
  .navbar_mobile a:hover, .navbar_mobile .active, .navbar_mobile li:hover > a {
    color: #d9232d;
  }
  .navbar_mobile .getstarted, .navbar_mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar_mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar_mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar_mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar_mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar_mobile .dropdown ul a:hover, .navbar_mobile .dropdown ul .active:hover, .navbar_mobile .dropdown ul li:hover > a {
    color: #d9232d;
  }
  .navbar_mobile .dropdown > .dropdown_active {
    display: block;
  }