/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(63, 73, 83, 0.8);
  overflow: hidden;
  position: relative;
}

.carousel_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
}

.hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
}
.hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #d9232d;
}
.btn_get_started:hover {
  background: #df3740;
  color: white;
}
@media (max-width: 992px) {
  .hero {
    height: 100vh;
  }
  .hero .carousel_container {
    top: 8px;
  }
}
@media (max-width: 768px) {
  .hero h2 {
    font-size: 28px;
  }
}

@media (max-height: 500px) {
  .hero {
    height: 120vh;
  }
}
