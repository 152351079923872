/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonial_item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}
.testimonial_item img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}
.testimonial_item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonial_item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.quote_left,
.quote_right {
  color: #f8d1d3;
  font-size: 26px;
}
.quote_left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.quote_right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonial_item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}
