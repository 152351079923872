/*--------------------------------------------------------------
# Iot
--------------------------------------------------------------*/
.iot h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.iot h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}
.iot ul {
  list-style: none;
  padding: 0;
}
.iot ul li {
  padding: 10px 0 0 28px;
  position: relative;
}
.iot ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #d9232d;
}
.iot p:last-child {
  margin-bottom: 0;
}
