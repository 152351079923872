body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

a {
  text-decoration: none;
  color: #d9232d;
}

a:hover {
  color: #e24d55;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}
