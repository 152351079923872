/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.info {
  width: 100%;
  background: #fff;
}
.info i {
  font-size: 20px;
  color: #556270;
  float: left;
  width: 44px;
  height: 44px;
  background: #edeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #556270;
}
.info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #8795a4;
}
.email,
.phone {
  margin-top: 40px;
}
.email:hover i,
.address:hover i,
.phone:hover i {
  background: #556270;
  color: #fff;
}
.email_form {
  width: 100%;
  background: #fff;
}

.error_message {
  display: none;
  color: rgb(146, 135, 135);
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.error_message br + br {
  margin-top: 25px;
}
.sent_message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.email_form input,
.email_form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}
.email_form input:focus,
.email_form textarea:focus {
  border-color: #d9232d;
}
.email_form input {
  height: 44px;
}
.email_form textarea {
  padding: 10px 12px;
}
.email_form button[type="button"] {
  background: #d9232d;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
.email_form button[type="button"]:hover {
  background: #e24d55;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
