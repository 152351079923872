/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team_member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
  padding: 30px;
  border-radius: 4px;
  background: white;
}
.member_pic {
  overflow: hidden;
  width: 140px;
  border-radius: 4px;
}
.member_pic img {
  transition: ease-in-out 0.3s;
}
.team_member:hover img {
  transform: scale(1.1);
}
.member_info {
  padding-left: 30px;
}
.team_member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #556270;
}
.team_member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}
.team_member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #dee2e6;
  bottom: 0;
  left: 0;
}
.team_member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
.member_social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.member_social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: #8795a4;
}
.member_social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}
.member_social a:hover {
  background: #d9232d;
}
.member_social a + a {
  margin-left: 8px;
}
